/**
 * These are implemented as enums so it:
 * - just uses numbers under the hood that we can do maths with
 * - standardises names
 * - allows us to see the underlying values in intellisense
 */

export enum BorderRadius {
  TINY = 4,
  SMALL = 8,
  REGULAR = 10,
  MEDIUM = 12,
  LARGE = 18,
}

export enum Spacing {
  NONE = 0,
  THIN = 4,
  SMALL = 8,
  REGULAR = 10,
  MEDIUM = 12,
  COMFORTABLE = 16,
  LARGE = 20,
  HUGE = 30,
}

enum IconSize {
  REGULAR = 16,
  LARGE = 24,
}

export enum LogoSize {
  FAVICON = 16,
  SMALL = 48,
  MEDIUM = 72,
  HUGE = 110,
}

export enum ObjectWidth {
  MODAL = 450,
  CONTAINER = 1000,
}

enum BreakPoint {
  MEDIUM = 450,
  LARGE = 600,
}

export enum FontSize {
  SMALL = 12,
  MEDIUM = 14,
  LARGE = 16,
  GARGANTUAN = 36,
}

export enum Weight {
  REGULAR = 400,
  MEDIUM = 500,
  SEMI_BOLD = 600,
  BOLD = 700,
}

export enum Color {
  WHITE = '#FFFFFF',
  BLACK = '#000000',

  GREY_BACKGROUND = '#F6F6F7',
  GREY_CALLOUT_BACKGROUND = '#FAFCFE',
  GREY_BORDER = '#CBD5E0', // gray.300
  GREY_DIVIDER = '#F0F0F0',
  GREY_TEXT_LIGHT = '#B7B7B7',
  GREY_TEXT_MEDIUM = '#666',
  GREY_TEXT_DARK = '#616161',

  PURPLE_PRIMARY = '#8778F7',
  PURPLE_LIGHT = '#9AB3F4',

  DEEP_GREEN = '#163308',

  RED = '#E85D5D',
  RED_ACCENT = '#DF2424',
}

export default {
  borderRadius: BorderRadius,
  spacing: Spacing,
  logoSize: LogoSize,
  iconSize: IconSize,
  objectWidth: ObjectWidth,
  breakPoints: BreakPoint,
  fontSize: FontSize,
  weights: Weight,
  colors: Color,
} as const;
